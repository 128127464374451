const logo = () => (
  <svg width="70" height="53" viewBox="0 0 70 53" fill="none">
    <path
      d="M32.5667 50.1696C41.3578 48.6166 48.6188 47.3315 48.7022 47.3138L48.8539 47.2817L40.5541 37.4094C35.9893 31.9797 32.2544 27.5161 32.2544 27.4903C32.2544 27.4416 40.8246 3.84121 40.8728 3.75725C40.8889 3.72918 46.7211 13.7984 55.0106 28.1658C62.7713 41.6167 69.1696 52.7071 69.2291 52.811L69.3372 53L42.9599 52.9966L16.5827 52.9932L32.5667 50.1696ZM0.911011 47.159C0.911011 47.1451 4.82181 40.3563 9.60167 32.0727L18.2923 17.0117L28.4203 8.51229C33.9907 3.83762 38.5632 0.00717782 38.5816 0.000192019C38.5999 -0.0067924 38.5266 0.177637 38.4187 0.410037C38.3108 0.642437 33.3619 11.2573 27.4212 23.9986L16.6198 47.1646L8.76542 47.1744C4.4455 47.1799 0.911011 47.1729 0.911011 47.159Z"
      fill="#0089D6"
    />
  </svg>
)

export default logo
